<template>
    <h1>Bullet Cameras</h1>
    <div class="header">
        <p>1=黑龙区，2=闪烁区/着火区，3=燃烬区</p>
        <button class="addBtn" @click="addNewCamera">添加</button>
    </div>
    <div class="table-container">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>位置信息</th>
                    <th>IP</th>
                    <th>SN码</th>
                    <th>燃烧状态</th>
                    <th>熄灭状态</th>
                    <th>异常状态</th>
                    <th>LevelID</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <!-- 添加新摄像头的输入行 -->
                <tr v-if="isAdding">
                    <td></td>
                    <td><input class="smallInput" v-model="newCamera.Name" /></td>
                    <td>
                        <ul>
                            <li v-for="(pos, index) in newCamera.Position" :key="index" class="position-item">
                                区域：<input class="regionInput" v-model="pos.Region" placeholder="区域" />
                                属性：<input class="smallInput" v-model="pos.Property" placeholder="属性" />
                                阈值：<input class="smallInput" v-model="pos.Threshold" placeholder="阈值" />
                                <button @click="addPosition(newCamera.Position)" class="addPositionBtn">+</button>
                            </li>
                        </ul>
                    </td>
                    <td><input class="zhongInput" v-model="newCamera.IP" /></td>
                    <td><input class="smallInput" v-model="newCamera.EdgeServerCPUSerialNumber" /></td>
                    <td><input class="smallInput" v-model="newCamera.CombustionState" /></td>
                    <td><input class="smallInput" v-model="newCamera.ExtinguishedState" /></td>
                    <td><input class="smallInput" v-model="newCamera.AbnormalState" /></td>
                    <td><input class="smallInput" v-model="newCamera.LevelID" /></td>
                    <td>
                        <button @click="saveNewCamera">保存</button>
                        <button @click="cancelAdding">取消</button>
                    </td>
                </tr>
                <!-- 现有摄像头数据 -->
                <tr v-for="item in data" :key="item.ID">
                    <td>{{ item.id }}</td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Name }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.Name" />
                        </div>
                    </td>
                    <td>
                        <ul>
                            <li v-for="(pos, index) in item.Position" :key="index" class="position-item">
                                <div v-if="!item.isEditing">
                                    区域: {{ pos.Region || '未知' }}, 属性: {{ pos.Property || '未知' }}, 阈值: {{ pos.Threshold || '未知' }}
                                </div>
                                <div v-else>
                                    区域：<input class="regionInput" v-model="pos.Region" placeholder="区域" />
                                    属性：<input class="smallInput" v-model="pos.Property" placeholder="属性" />
                                    阈值：<input class="smallInput" v-model="pos.Threshold" placeholder="阈值" />
                                    <button @click="addPosition(item.Position)" class="addPositionBtn">+</button>
                                </div>
                            </li>
                            <li v-if="!getValidPositions(item.Position).length">No Positions</li>
                        </ul>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.IP }}</div>
                        <div v-else><input class="zhongInput" v-model="item.IP" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.EdgeServerCPUSerialNumber }}</div>
                        <div v-else><input class="smallInput" v-model="item.EdgeServerCPUSerialNumber" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.CombustionState }}</div>
                        <div v-else><input class="smallInput" v-model="item.CombustionState" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.ExtinguishedState }}</div>
                        <div v-else><input class="smallInput" v-model="item.ExtinguishedState" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.AbnormalState }}</div>
                        <div v-else><input class="smallInput" v-model="item.AbnormalState" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">{{ item.LevelID }}</div>
                        <div v-else><input class="smallInput" v-model="item.LevelID" /></div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            <button @click="editItem(item)">修改</button>
                            <button @click.prevent="confirmDelete(item.id)">删除</button>
                        </div>
                        <div v-else>
                            <button @click="saveItem(item)">保存</button>
                            <button @click="cancelEdit(item)">取消</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import axios from 'axios'; // 导入 axios

export default {
    data() {
        return {
            data: [], // 现有摄像头数据
            newCamera: { // 新摄像头数据
                Name: '',
                Position: [{ Region: '', Property: '', Threshold: '' }], // 初始位置数组
                EdgeServerCPUSerialNumber: '',
                IP: '',
                CombustionState: null,
                ExtinguishedState: null,
                AbnormalState: null,
                LevelID: null
            },
            isAdding: false // 添加状态控制
        };
    },
    inject: ['apiUrl'],
    mounted() {
        this.fetchCameras(); // 初始获取摄像头数据
    },
    methods: {
        fetchCameras() {
            axios.get(this.apiUrl + '/bulletcameras/getAllBulletcamera')
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        confirmDelete(id) {
            if (confirm('确定要删除此项吗？')) {
                this.deleteBulletCamera(id);
            }
        },
        async deleteBulletCamera(id) {
            try {
                await axios.delete(this.apiUrl + `/bulletcameras/deleteBulletcameraById?id=${id}`);
                alert('删除成功!');
                this.data = this.data.filter(item => item.id !== id);
            } catch (error) {
                alert('删除失败!');
                console.error('删除错误:', error);
            }
        },
        getValidPositions(positions) {
            if (!Array.isArray(positions)) {
                return [];
            }
            return positions.filter(pos => pos.Region && pos.Property && pos.Threshold);
        },
        addNewCamera() {
            this.isAdding = true; // 打开添加状态
            this.newCamera = { // 重置新摄像头数据
                Name: '',
                Position: [{ Region: '', Property: '', Threshold: '' }],
                EdgeServerCPUSerialNumber: '',
                IP: '',
                CombustionState: null,
                ExtinguishedState: null,
                AbnormalState: null,
                LevelID: null
            };
        },
        async saveNewCamera() {
            try {
                const response = await axios.post(this.apiUrl + `/bulletcameras/addBulletcamera`, this.newCamera);
                this.data.push(response.data); // 将新摄像头添加到数据中
                this.isAdding = false; // 关闭添加状态
                alert('添加成功！');
            } catch (error) {
                alert('添加失败！');
                console.error('添加错误:', error);
            }
        },
        cancelAdding() {
            this.isAdding = false; // 取消添加状态
        },
        addPosition(positionArray) {
            // 在对应的位置数组中添加一个新的空对象
            positionArray.push({ Region: '', Property: '', Threshold: '' });
        },
        editItem(item) {
            item.isEditing = true;
        },
        async saveItem(item) {
            try {
                await axios.put(this.apiUrl + `/bulletcameras/updateBulletcamera?id=${item.id}`, {
                    Name: item.Name,
                    Position: item.Position,
                    EdgeServerCPUSerialNumber: item.EdgeServerCPUSerialNumber,
                    IP: item.IP,
                    CombustionState: item.CombustionState,
                    ExtinguishedState: item.ExtinguishedState,
                    AbnormalState: item.AbnormalState,
                    LevelID: item.LevelID,
                });
                item.isEditing = false; // 结束编辑状态
                alert('保存成功！');
            } catch (error) {
                alert('保存失败！');
                console.error('保存错误:', error);
            }
        },
        cancelEdit(item) {
            item.isEditing = false; // 取消编辑状态
        }
    }
};
</script>



<style>
.addPositionBtn {
    margin-left: 5px;
    color: white;
    background-color: #757979; /* 绿色背景 */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width:20px;
    height: 20px;
}

button:hover{
    background-color: #a5e5a8; /* 深一点的绿色 */

}
.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
    margin: 0 auto; 
    padding: 10px;
}
button {
    cursor: pointer;
    width: 60px;
    height: 30px;
    margin: 5px;
}
.addBtn {
    margin-right: 20px;
    width: 80px;
    height: 30px;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

table {
    width: 70%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.position-item {
    padding: 10px 5px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    border: 1px dashed #ccc;
    margin-bottom: 2px;
    height: 40px;
}
.smallInput {
    width: 50px;
    height: 20px;
}
.zhongInput {
    width: 100px;
    height: 20px;
}
.regionInput {
    width: 120px;
    height: 20px;
}
</style>