<template>
  <div id="app">
    <h1>XCC - 徐州燃控</h1>
    
    <!-- 按钮和导航 -->
    <div class="nav-menu">
      <ul>
        <li 
          @click="setCurrentComponent('BulletCameraTable')" 
          :class="{ active: currentComponent === 'BulletCameraTable' }"
        >
          监控摄像头
        </li>
        <li 
          @click="setCurrentComponent('LevelTable')" 
          :class="{ active: currentComponent === 'LevelTable' }"
        >
          级别表
        </li>
        <li 
          @click="setCurrentComponent('alertrecordTable')" 
          :class="{ active: currentComponent === 'alertrecordTable' }"
        >
        Alertrecord
        </li>
      </ul>
    </div>

    <!-- 根据当前组件显示不同的内容 -->
    <component :is="currentComponent" />
  </div>
</template>

<script>
import BulletCameraTable from './components/BulletCameraTable/BulletCameraTableIndex.vue';
import LevelTable from './components/LevelTable/LevelTableIndex.vue';
import alertrecordTable from './components/AlertrecordTable/AlertrecordTableIndex.vue';

export default {
  components: {
    BulletCameraTable,
    LevelTable,
    alertrecordTable
  },
  
  data() {
    return {
      showNav: false,
      currentComponent: 'BulletCameraTable'
    };
  },

  provide() {
    return {
      apiUrl: 'http://192.168.10.214:3000'
    };
  },
  
  methods: {
    setCurrentComponent(component) {
      this.currentComponent = component;
      this.showNav = false;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  margin-top: 60px;
  text-align: center;
}

/* 导航菜单样式 */
.nav-menu {
  position: absolute;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  cursor: pointer;
  padding: 5px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu li:hover {
  background-color: #f0f0f0;
}

.nav-menu li.active {
  background-color: #d3d3d3; /* 当前选中项的背景色 */
}
</style>
