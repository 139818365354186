<template>
    <h1>Alertrecord</h1>
    <div class="header">
        <button class="addBtn" @click="addNewLevel">添加</button>
    </div>
    <div class="table-container">
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>名称</th>
                    <th>内容</th>
                    <th>编码</th>
                    <th>权重</th>
                    <th>时间</th>
                    <th>相机ID</th>
                    <th>服务器 ID</th>
                    <th>服务器SN</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <!-- 添加新摄像头的输入行 -->
                <tr v-if="isAdding">
                    <td></td>
                    <td><input class="smallInput" v-model="newLevel.Name" /></td>
                    <td><input class="smallInput" v-model="newLevel.Content" /></td>
                    <td><input class="smallInput" v-model="newLevel.Code" /></td>
                    <td><input class="smallInput" v-model="newLevel.Importance" /></td>
                    <td><input type="datetime-local" class="timeInput" v-model="newLevel.Time" /></td>
                    <td><input class="smallInput" v-model="newLevel.CameraID" /></td>
                    <td><input class="smallInput" v-model="newLevel.EdgeServerID" /></td>
                    <td><input class="smallInput" v-model="newLevel.EdgeServerSN" /></td>
                    
                    <td>
                        <button @click="saveNewLevel">保存</button>
                        <button @click="cancelAdding">取消</button>
                    </td>
                </tr>
                <!-- 现有摄像头数据 -->
                <tr v-for="item in data" :key="item.ID">
                    <td>{{ item.id }}</td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Name }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.Name" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Content }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.Content" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Code }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.Code" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Importance }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.Importance" />
                        </div>
                    </td>

                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.Time }}
                        </div>
                        <div v-else>
                            <input type="datetime-local" class="timeInput" v-model="item.Time" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.CameraID }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.CameraID" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.EdgeServerID }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.EdgeServerID" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            {{ item.EdgeServerSN }}
                        </div>
                        <div v-else>
                            <input class="smallInput" v-model="item.EdgeServerSN" />
                        </div>
                    </td>
                    <td>
                        <div v-if="!item.isEditing">
                            <button @click="editItem(item)">修改</button>
                            <button @click.prevent="confirmDelete(item.id)">删除</button>
                        </div>
                        <div v-else>
                            <button @click="saveItem(item)">保存</button>
                            <button @click="cancelEdit(item)">取消</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios'; // 导入 axios

export default {
    data() {
        return {
            data: [], // 现有摄像头数据
            newLevel: { // 新摄像头数据
                Name: '',
               
            },
            isAdding: false // 添加状态控制
        };
    },
    inject: ['apiUrl'],
    mounted() {
        this.fetchLevels(); // 初始获取摄像头数据
    },
    methods: {
        fetchLevels() {
            axios.get(this.apiUrl + '/alertrecords/getAllAlertrecords')
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        confirmDelete(id) {
            if (confirm('确定要删除此项吗？')) {
                this.deleteBulletLevel(id);
            }
        },
        async deleteBulletLevel(id) {
            try {
                await axios.delete(this.apiUrl + `/alertrecords/deleteAlertrecordById?id=${id}`);
                alert('删除成功!');
                this.data = this.data.filter(item => item.id !== id);
            } catch (error) {
                alert('删除失败!');
                console.error('删除错误:', error);
            }
        },
        addNewLevel() {
            this.isAdding = true; // 打开添加状态
            this.newLevel = { // 重置新摄像头数据
                Name: '',
            };
        },
        async saveNewLevel() {
            try {
                const response = await axios.post(this.apiUrl + `/alertrecords/addAlertrecords`, this.newLevel);
                this.data.push(response.data); // 将新摄像头添加到数据中
                this.isAdding = false; // 关闭添加状态
                alert('添加成功！');
                await this.fetchLevels(); // 确保数据是最新的
            } catch (error) {
                alert('添加失败！');
                console.error('添加错误:', error);
            }
        },
        cancelAdding() {
            this.isAdding = false; // 取消添加状态
        },
        editItem(item) {
            item.isEditing = true;
        },
        async saveItem(item) {
            try {
                await axios.put(this.apiUrl + `/alertrecords/updateAlertrecord?id=${item.id}`, {
                    Name: item.Name,
                    Content: item.Content,
                    Code: item.Code,
                    Importance: item.Importance,
                    Time: item.Time,
                    CameraID: item.CameraID,
                    EdgeServerID: item.EdgeServerID,
                    EdgeServerSN: item.EdgeServerSN,
                });
                item.isEditing = false; // 结束编辑状态
                alert('保存成功！');
            } catch (error) {
                alert('保存失败！');
                console.error('保存错误:', error);
            }
        },
        cancelEdit(item) {
            item.isEditing = false; // 取消编辑状态
        }
    }
};
</script>


<style>
/* 这里的样式不变 */
button:hover {
    background-color: #a5e5a8; /* 深一点的绿色 */
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
    margin: 0 auto; 
    padding: 10px;
}

button {
    cursor: pointer;
    width: 60px;
    height: 30px;
    margin: 5px;
}

.addBtn {
    margin-right: 20px;
    width: 80px;
    height: 30px;
}

.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

table {
    width: 70%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.position-item {
    padding: 10px 5px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    border: 1px dashed #ccc;
    margin-bottom: 2px;
    height: 40px;
}

.smallInput {
    width: 50px;
    height: 20px;
}

.zhongInput {
    width: 100px;
    height: 20px;
}

.regionInput {
    width: 120px;
    height: 20px;
}
.timeInput{
    width: 160px;
    height: 20px;

}
</style>